<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>About <span>Padmini Farms</span></h2>
            <p>Our journey began with a passion for preserving the raw beauty of the mountains and sharing it with those seeking solace from the chaos of city life. Padmini Farms was founded by Shanmugam & Sons, a true mountain enthusiast who envisioned a place where guests could immerse themselves in the enchanting landscapes while experiencing the authenticity of farm life.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>We are <span>third generation family</span>  farmers.</h2>
                        <p>Teaming up with nature for a beautiful tomorrow.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Guided Farm Tours</li>
                        <li><i class="fas fa-check"></i>Harvest and Cooking Workshops</li>
                        <li><i class="fas fa-check"></i>Nature Walks and Hikes</li>
                        <li><i class="fas fa-check"></i>Stargazing Sessions</li>
                        <li><i class="fas fa-check"></i>Farm Photography Workshops</li>
                        <li><i class="fas fa-check"></i>Yoga and Wellness Retreats</li>
                        <li><i class="fas fa-check"></i>Art and Craft Workshops</li>
                        <li><i class="fas fa-check"></i>Picnic Experiences</li>
                        <li><i class="fas fa-check"></i>Yoga and Wellness Retreats</li>
                        <li><i class="fas fa-check"></i>Flower Picking and Arranging</li>
                        <li><i class="fas fa-check"></i>Farm Animal Interaction</li>
                        <li><i class="fas fa-check"></i>Candlelit Dinners</li>
                        <li><i class="fas fa-check"></i>Outdoor Movie Nights</li>
                        <li><i class="fas fa-check"></i>Farm-Focused Classes</li>
                        <li><i class="fas fa-check"></i>Foraging Adventures</li>
                        <li><i class="fas fa-check"></i>Farm Stay Photography</li>
                    </ul>
                    <!--<p>Where people and nature co-exist in harmony.</p>-->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about-pfarms.jpg" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>About Us</h2>
            <p>Welcome to Padmini Farms, your serene escape nestled in the heart of the majestic mountains. We're more than just a place to rest; we're a haven of tranquility, a sanctuary where nature's embrace and the warm comforts of rural living come together.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>The Team</h3>
                    <p>Behind Padmini Farms is a team of passionate individuals who share a love for nature, a dedication to hospitality, and a genuine desire to make your stay unforgettable. From farm tours to cozy campfires under starlit skies, we're here to curate an experience that resonates with your soul.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>We are passionate</h3>
                    <p>We are committed to sustainability and responsible land stewardship. Our organic practices reflect our deep respect for the environment, ensuring that the beauty of these mountains remains untouched for generations to come. From the food on your plate to the energy that powers your stay, every aspect is carefully considered to minimize our ecological footprint.</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>Nature's Canvas</h3>
                    <p>The mountains themselves are our canvas, and we invite you to be a part of this natural masterpiece. Let the awe-inspiring vistas spark your creativity and provide the backdrop for your own artistic journey. Whether you're a painter capturing the vivid hues of dawn or a photographer framing the perfect shot, our landscapes will ignite your imagination.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Your Creative Getaway</h3>
                    <p>Whether you're seeking artistic inspiration, culinary exploration, or simply a space to let your imagination roam free, [Farm Name] is your canvas. We invite you to join us in celebrating the beauty of creativity in all its forms, discovering the joy of expressing yourself in the heart of the mountains. Come, experience a farm stay where every moment is an opportunity to unleash your creativity and make memories that color your life.</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>Escape to Elegance</h3>
                    <p>Step into a world where luxury intertwines with simplicity, where nature's rhythm harmonizes with the beat of your heart. Padmini Farms is not just a destination; it's a gateway to a unique experience that reflects your individuality. We invite you to immerse yourself in an ambiance that celebrates the art of being different, where every moment is a testament to our commitment to being distinctly, beautifully, and unapologetically unique.</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>Embrace the Extraordinary: We Are Unique</h3>
                    <p>Padmini Farms stands as a testament to individuality, a realm where the ordinary dissolves and the extraordinary takes center stage. Our farm stay is a harmonious blend of nature's grandeur, artisanal craftsmanship, and heartfelt hospitality, setting us apart in a world brimming with possibilities.</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
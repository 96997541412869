<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/Farm-to-Table-Integrity.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <!--h4>Highly Creative Solutions</h4-->
                        <h2>About <span>Strategy</span></h2>
                        <p>Teaming up with nature for a beautiful tomorrow.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Sustainability at the Core</li>
                        <li><i class="fa fa-check"></i>Organic Excellence</li>
                        <li><i class="fa fa-check"></i>Harvesting Rainwater, Preserving Resources</li>
                        <li><i class="fa fa-check"></i>Farm-to-Table Integrity</li>
                        <li><i class="fa fa-check"></i>Transparency and Education</li>
                        <li><i class="fa fa-check"></i>Continuous Improvement</li>
                        <li><i class="fa fa-check"></i>Empowering Community</li>
                    </ul>
                    <p>Our strategy is not limited to any one aspect; it's a holistic endeavor that harmonizes the different elements of our farm stay. Whether you're savoring a meal, exploring the fields, or simply enjoying the tranquility, every experience at Padmini Farms is rooted in our commitment to sustainability, organic practices, water harvesting, and the Farm-to-Table movement.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
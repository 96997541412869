<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg-one">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h4>We Are Farmers</h4>
                                <h1>Nurturing <span>Earth</span>, Growing <span>Organically</span>.</h1>
                                <p>Teaming up with nature for a beautiful tomorrow.</p>
                                <a href="#about" class="btn btn-primary">Get Started</a>
                                <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg-two">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h4>A decision for nature</h4>
                                <h1><span>Nature's Guardians:</span> Tending to Organic Lands, Growing Dreams.</h1>
                                <p>Teaming up with nature for a beautiful tomorrow.</p>
                                <a href="#about" class="btn btn-primary">Get Started</a>
                                <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg-three">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h4>Nature lies in our hands</h4>
                                <h1>Where <span>Farming and Nature</span> Coexist Organically.</h1>
                                <p>Teaming up with nature for a beautiful tomorrow.</p>
                                <a href="#about" class="btn btn-primary">Get Started</a>
                                <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>
<!--
<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>
-->
<app-contact></app-contact>